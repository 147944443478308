b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  margin: 0.5em 0 0.3em;
}

h1 {
  font-weight: $weight-bold;
  color: $color-green;
}

h2 {
  font-weight: $weight-semi-bold;
  color: $color-green;
}

h4 {
  letter-spacing: 1px;
}

// size
h1 {
  font-size: rem(36, $font-size-mobile);
}
h2 {
  font-size: rem(28, $font-size-mobile);
}
h3 {
  font-size: rem(24, $font-size-mobile);
}
h4 {
  font-size: rem(20, $font-size-mobile);
}
h5 {
  font-size: rem(14, $font-size-mobile);
}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(60, $font-size-desktop);
  }
  h2 {
    font-size: rem(48, $font-size-desktop);
  }
  h3 {
    font-size: rem(36, $font-size-desktop);
  }
  h4 {
    font-size: rem(30, $font-size-desktop);
  }
  h5 {
    font-size: rem(16, $font-size-desktop);
  }
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}
.text-big {
  font-size: 1.5rem;
}
